/* styles.css */
.animated-title {
  font-size: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-weight: 600;
  line-height: 1;
  color: #002864;
  padding-left: 32px;
  position: relative;
  font-family: Lato, sans-serif;
  margin-bottom: 16px;
  @media screen and (min-width: 600px) {
    font-size: 18px;
  }
  @media screen and (min-width: 767px) {
    font-size: 23px;
  }
  a {
    color: #002864;
  }
  &:hover {
    color: #d22242;
    transition: color 0.4s;
    a {
      color: #d22242;
      transition: color 0.4s;
    }
  }
  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    background-image: url('~@/img/icons/tripple-arrow-icon.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    height: 19px;
    width: 22px;
  }
}

.animated-title span {
  display: inline-block;
  transform: translateY(30px);
  //animation: fadeUpAnimatedTitle 0.5s forwards;
}

@keyframes fadeUpAnimatedTitle {
  to {
    transform: translateY(0);
  }
}

.animated-title span:nth-child(1) { animation-delay: 0.15s; }
.animated-title span:nth-child(2) { animation-delay: 0.25s; }
.animated-title span:nth-child(3) { animation-delay: 0.35s; }
.animated-title span:nth-child(4) { animation-delay: 0.45s; }
.animated-title span:nth-child(5) { animation-delay: 0.55s; }
.animated-title span:nth-child(6) { animation-delay: 0.65s; }
.animated-title span:nth-child(7) { animation-delay: 0.75s; }
.animated-title span:nth-child(8) { animation-delay: 0.85s; }
.animated-title span:nth-child(9) { animation-delay: 0.95s; }


