.lazyframe[data-vendor="youtube"],
.lazyframe[data-vendor="youtube_nocookie"] {
  background-color: #e52d27;
  font-family: Roboto, Arial, Helvetica, sans-serif;

  .lazyframe__title {
    color: rgb(238, 238, 238);
    font-family: Roboto, Arial, Helvetica, sans-serif;
    font-size: 18px;
    text-shadow: rgba(0, 0, 0, .498039) 0 0 2px;
    -webkit-font-smoothing: antialiased;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    transition: color .1s cubic-bezier(.4, 0, 1, 1);

    &:hover {
      color: #fff;
    }

    &::before {
      content: "";
      display: block;
      background: linear-gradient(rgba(0, 0, 0, .2), transparent);
      height: 98px;
      width: 100%;
      pointer-events: none;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      z-index: -1;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }

  }

  &::before {
    padding-top: 56.25%;
  }

  &::after {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    width: 68px;
    height: 48px;
    margin-left: -34px;
    margin-top: -24px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='%231F1F1F' d='M255.7 446.3c-53.3.3-106.6-.4-159.8-3.3-17.4-1-34.7-2.5-50.4-11C35 426.3 27 418.4 22 407.2 13.2 388.6 10.5 369 9 349c-3.4-41.3-3.6-82.6-1.8-123.8 1-22 1.6-44 6.8-65.5 2-8.4 5-16.6 8.8-24.4C32 117 48 108 67.3 104c16.2-3 32.8-3 49.3-3.7 56-2.3 112-3.5 168-3 43 .6 86.2 1.7 129.3 4 13.2.6 26.6.8 39.3 5.5 17.2 6.4 30 17.2 37 34.7 6.6 16.8 9.2 34.2 10.6 52 3.8 48.7 4 97.3.7 146-1 16.3-2.2 32.7-6.5 48.8-9.7 37-32.8 51.5-66.7 53.8-36.2 2.5-72.5 3.8-108.8 4.3-21.3.2-42.7 0-64 0zM203.2 344L348 264.7l-144.8-79.3V344z'/%3E%3Cpath fill='%23FEFDFD' d='M203.2 344V185.5L348 264.8 203.2 344z'/%3E%3C/svg%3E");
    background-position: center center;
    background-size: 100%;
    background-repeat: no-repeat;
    opacity: .81;

    border: none;
    z-index: 4;
  }

  &:hover::after {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='%23DD2C28' d='M255.7 446.3c-53.3.3-106.6-.4-159.8-3.3-17.4-1-34.7-2.5-50.4-11C35 426.3 27 418.4 22 407.2 13.2 388.6 10.5 369 9 349c-3.4-41.3-3.6-82.6-1.8-123.8 1-22 1.6-44 6.8-65.5 2-8.4 5-16.6 8.8-24.4C32 117 48 108 67.3 104c16.2-3 32.8-3 49.3-3.7 56-2.3 112-3.5 168-3 43 .6 86.2 1.7 129.3 4 13.2.6 26.6.8 39.3 5.5 17.2 6.4 30 17.2 37 34.7 6.6 16.8 9.2 34.2 10.6 52 3.8 48.7 4 97.3.7 146-1 16.3-2.2 32.7-6.5 48.8-9.7 37-32.8 51.5-66.7 53.8-36.2 2.5-72.5 3.8-108.8 4.3-21.3.2-42.7 0-64 0zM203.2 344L348 264.7l-144.8-79.3V344z'/%3E%3Cpath fill='%23FEFDFD' d='M203.2 344V185.5L348 264.8 203.2 344z'/%3E%3C/svg%3E");
    opacity: 1;
  }

}
