.card-bg-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;
}

.card-overlay, {
  z-index: 1;
}

.card-link {
  z-index: 2;
}