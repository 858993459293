.cover-image-single {
  height: 100%;
  width: 100%;
  display: block;

  img {
    display: block;
    margin: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}