.lazyframe[data-vendor="vimeo"] {
  background-color: #00adef;

  .lazyframe__title {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    color: #00adef;
    font-size: 20px;
    font-weight: 700;
    text-rendering: optimizeLegibility;
    user-select: none;
    -webkit-font-smoothing: auto;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    background-color: rgba(0, 0, 0, .5);
  }

  &::before {
    padding-top: 48.25%;
  }

  &::after {
    content: "";
    height: 40px;
    width: 65px;
    display: block;
    bottom: 10px;
    left: 10px;
    z-index: 3;
    background-color: rgba(0, 0, 0, .5);
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' baseProfile='tiny' viewBox='0 0 24 24'%3E%3Cpath fill='%23FFF' d='M7.765 16.89l8.47-4.89-8.47-4.89'/%3E%3C/svg%3E");
    background-position: center center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    border-radius: 5px;
    position: relative;
  }

  &:hover::after {
    background-color: #00adef;
  }

}
