@font-face {
  font-family: 'Acumin pro', sans-serif;
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url('../../fonts/Acumin-RPro.woff2') format('woff2');
text-rendering: optimizeLegibility; }

@font-face {
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url('../../fonts/Acumin-BdPro.woff2') format('woff2');
text-rendering: optimizeLegibility; }